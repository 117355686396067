import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '../../../shared/Modal';
import ApiService, {GameMap} from '../../../services/api';
import Spinner from '../../../shared/Spinner';

import sShared from '../../../shared/shared.module.css';
import s from './index.module.css';


function MapsModal(props: {show: boolean, onHide: () => void}) {
    const [maps, setMaps] = useState<GameMap[]>([]);
    const {t} = useTranslation();

    useEffect(() => {
        const apiService = ApiService.instance;
        
        if (props.show && maps.length === 0)
            apiService.getMaps().then(mps => setMaps(mps));
    }, [props.show, maps]);
    
    return (
        <Modal title={t('resources.maps')} show={props.show} onHide={() => props.onHide()}>
            {maps.length === 0 && <Spinner />}

            <section className={[sShared.container, s.container].join(' ')}>
                {maps.map(map => (
                    <article key={map.id}>
                        <img alt={map.name} src={map.image} loading="lazy"/>
                        <span>{map.name}</span>
                    </article>
                ))}
            </section>
        </Modal>
    );
}

export default MapsModal;
