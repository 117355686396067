import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, withRouter, RouteChildrenProps} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';

import ApiService from '../../services/api';

import iconJpg from '../../assets/icon.jpg';
import sShared from '../../shared/shared.module.css';
import s from './index.module.css';


function Header(props: RouteChildrenProps) {
    const {t, i18n} = useTranslation();
    const [countOnline, setOnline] = useState(0);    

    useEffect(() => {
        const apiService = ApiService.instance;
        
        apiService.getOnlinePlayers().then(count => setOnline(count));
    }, []);

    return (
        <header className={[s.header].join(' ')}>
            <section className={[sShared.container, s.container].join(' ')}>
                <a className={s.logo} href={`/${i18n.language}/`}>
                    <img width="40" height="40" className={s.logoImage} alt={t('resources.logo')} src={iconJpg} />
                    DowOnline
                </a>

                <nav className={s.nav}>                
                    <HashLink to="/#how-to-play" className={s.link}>{t('resources.howToPlay')}</HashLink>
                    <HashLink to="/#maps-and-patchs" className={s.link}>{t('resources.mapsAndPatchs')}</HashLink>
                    <HashLink to="/#contacts" className={s.link}>{t('resources.contacts')}</HashLink>
                    <NavLink to={{pathname: '/stats/', search: props.location.search}} className={s.link}>{t('resources.rating')}</NavLink>
                    <a href="https://dowstats.ru/index.php?server=dowonline" className={s.link} target="_blank" rel="noopener noreferrer">{t('resources.dowstats')}</a>
                </nav>
                
                <span className={[
                    s.stats,
                    !countOnline ? s.statsHide : undefined
                ].join(' ')}>
                    {t('resources.online')}: {countOnline}
                </span>
            </section>
        </header>
    );
}

export default withRouter(Header);
