import React from 'react';
import ReactDOM from 'react-dom';
import 'focus-visible';
import './index.css';
import App from './App';
import './i18n';


const rootElement = document.getElementById('root');

if (rootElement && rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<App />, rootElement);
} else {
    ReactDOM.render(<App />, rootElement);
}