import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import Select, {SelectItem} from '../../shared/Select';
import {LANGUAGES} from '../../i18n';

import {ReactComponent as LanguageSvg} from '../../assets/language.svg';
import sShared from '../../shared/shared.module.css';
import s from './index.module.css';



function Footer() {
    const [currentItem, setItem] = useState<SelectItem>(LANGUAGES[0]);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const item = LANGUAGES.find(item => item.code === i18n.language) || LANGUAGES[0];
        setItem(item);
    }, [i18n.language]);

    return (
        <footer className={[s.footer, 'notranslate'].join(' ')}>
            <section className={[sShared.container, s.container].join(' ')}>
                {t('footer.info')}
                <Select 
                    className={s.select} 
                    items={LANGUAGES}
                    directionDropDown="up"
                    change={(
                        item =>
                            window.location.href = window.location.pathname.replace(i18n.language, item.code)
                    )}
                >
                    {currentItem.label}
                    <LanguageSvg className={s.iconLang} />
                </Select>
            </section>
        </footer>
    );
}

export default Footer;
