import React, {PropsWithChildren, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import s from './index.module.css';


export interface SelectItem {
    id: number;
    label: string;
    [key: string]: any;
}

interface SelectProps {
    items: SelectItem[];
    change: (item: SelectItem) => void;
    className?: string;
    dropDownClasName?: string;
    directionDropDown?: 'up' | 'down';
    arrow?: boolean;
    search?: boolean;
}

export default function Select(props: PropsWithChildren<SelectProps>) {
    const {t} = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const mainClass = s.container + ' ' + (props.className || '');
    const buttonClass = s.button + (props.arrow ? ' ' + s.buttonArrow : '');
    const arrowClass= s.arrow + ' ' + (isOpen ? s.arrowUp : s.arrowDown);

    const height = 
        props.search ?
            `calc(${props.items.length * 100}% + 50)` :
            `${props.items.length * 100}%`;

    const list = props.items.filter(item => 
        !searchText || item.label.toLowerCase().includes(searchText.toLowerCase())
    );

    let dropdownClass = s.dropdown + ' ' + (props.dropDownClasName ?? '');
    
    if (props.directionDropDown === 'up') {
        dropdownClass += ' ' + s.dropdownUp;
    } else {
        dropdownClass += ' ' + s.dropdownDown;
    }

    useEffect(() => {
        const onClickOutside = () => isOpen && setOpen(false);
        const onKeyDown = (e: KeyboardEvent) => e.keyCode === 27 && onClickOutside();

        document.addEventListener('click', onClickOutside);
        document.addEventListener('keydown', onKeyDown);
        
        return () => {
            document.removeEventListener('click', onClickOutside);
            document.removeEventListener('keydown', onKeyDown);
        };
    });

    return (
        <section className={mainClass}>
            <button type="button" className={buttonClass} onClick={() => setOpen(!isOpen)}>
                {props.children}
            </button>
            {props.arrow && <div className={arrowClass}></div>}
            {isOpen && (
                <section
                    className={dropdownClass}
                    style={{height}}
                >
                    {props.search &&
                        <section className={s.searchWrapper}>
                            <input
                                type="search"
                                autoFocus={true}
                                className={s.search}
                                onInput={e => setSearchText((e.target as any).value)}
                                onClick={e => e.stopPropagation()}
                            />
                        </section>
                    }
                    {
                        list.length > 0 ?
                            list.map(item =>
                                <button
                                    className={s.item}
                                    key={item.id}
                                    title={item.label}
                                    onClick={() => props.change(item)}
                                >
                                    {item.label}
                                </button>
                            ) :
                            <span className={s.notFound}>{t('resources.notFound')}</span>
                    }
                </section>
            )}
        </section>
    );
}