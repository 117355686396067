import React, {PropsWithChildren, useState} from 'react';
import YouTube from 'react-youtube';

import {ReactComponent as YouTubeButtonSvg} from '../../assets/youtube.svg';
import s from './index.module.css';


function YouTubePlayer(props: PropsWithChildren<{id: string, title: string, className?: string}>) {
    const [isCover, setCover] = useState(true);

    return (
        <button className={s.cover + (props.className ? ' ' + props.className : '')}
            style={{backgroundImage: `url(https://i.ytimg.com/vi/${props.id}/sddefault.jpg)`}}
            onClick={() => isCover && setCover(false)}
        >
            {!isCover ?
                <YouTube className={s.youtube} videoId={props.id} opts={{playerVars: {autoplay: 1}}} /> :
                (<>
                    <span className={s.title}>{props.title}</span>
                    <section className={s.buttonWrapper}>
                        <YouTubeButtonSvg />
                    </section>
                </>)
            }
        </button>
    );
}

export default YouTubePlayer;
