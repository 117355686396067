import React, {useState} from 'react';
import {useTranslation, Trans} from 'react-i18next';

import Button from '../../shared/Button';
import MutedPlayer from '../../shared/MutedPlayer';
import YouTubePlayer from '../../shared/YouTubePlayer';
import MapsModal from './MapsModal';
import PatchsModal from './PatchsModal';

import videoMp4 from '../../assets/video.mp4';
import videoWebm from '../../assets/video.webm';
import {ReactComponent as DiscordSvg} from '../../assets/discord.svg';
import sShared from '../../shared/shared.module.css';
import s from './index.module.css';


function Home() {
    const [isMapsModalOpen, setMapsModalOpen] = useState(false);
    const [isPatchsModalOpen, setPatchsModalOpen] = useState(false);
    const {t, i18n} = useTranslation();

    return (
        <main className={s.main}>

            <section className={s.banner}>
                <MutedPlayer loop={true} className={s.bannerPreview}>
                    <source src={videoMp4} type="video/mp4" />
                    <source src={videoWebm} type="video/webm" />
                </MutedPlayer>
                <section>
                    <h1>DowOnline</h1>
                    <h2>{t('home.description')}</h2>
                    <Button href="/clientBin/DowOnline.Installer.exe">{t('home.download')}</Button>
                    <a className={s.bannerLink} href="/clientBin/DowOnlineLatest.zip">{t('home.zipDownload')}</a>
                </section>
            </section>

            <section id="how-to-play" className={[sShared.container, s.section].join(' ')}>
                <h2>{t('resources.howToPlay')}</h2>

                <ul>
                    <li>
                        <i>1</i><span>{t('home.howToPlayList.0')}</span>
                    </li>
                    <li>
                        <i>2</i>
                        <span>
                            <Trans i18nKey="home.howToPlayList.1">
                                <a target="_blank" rel="nofollow noopener noreferrer" href="https://discord.gg/n72AvaTAfN">&nbsp;</a>
                            </Trans>
                        </span>
                    </li>
                    <li>
                        <i>3</i>
                        <span>
                            <Trans i18nKey="home.howToPlayList.2">
                                <a target="_blank" href="/clientBin/DowOnline.Installer.exe">&nbsp;</a>
                            </Trans>
                        </span>
                    </li>
                    <li>
                        <i>4</i>
                        <span>
                            <Trans i18nKey="home.howToPlayList.3">
                                <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.microsoft.com/ru-ru/download/details.aspx?id=30653">&nbsp;</a>
                            </Trans>
                        </span>
                    </li>
                    <li>
                        <i>5</i>
                        <span>{t('home.howToPlayList.4')}</span>
                    </li>
                </ul>

                {i18n.language === 'ru' && 
                    <YouTubePlayer className={s.youtube} id="K1UawKS5hlQ" title="DowOnline: Инструкция. Как пользоваться и где скачать." />
                }
            </section>

            <section id="maps-and-patchs" className={s.modsAndMaps}>
                <div></div>
                <section className={sShared.container}>
                    <article>
                        <h2>{t('resources.maps')}</h2>
                        <span>{t('home.mapsDescription')}</span>
                        <Button onClick={() => setMapsModalOpen(true)}>{t('home.openModal')}</Button>
                    </article>

                    <article>
                        <h2>{t('resources.patchs')}</h2>
                        <span>{t('home.patchsDescription')}</span>
                        <Button onClick={() => setPatchsModalOpen(true)}>{t('home.openModal')}</Button>
                    </article>
                </section>
            </section>


            <section id="contacts" className={[sShared.container, s.section, s.contacts].join(' ')}>
                <h2>{t('resources.contacts')}</h2>
                <span>
                    <Trans i18nKey="home.contacts">
                        <a target="_blank" rel="noopener noreferrer" href="https://onepopular-mapping.ru/">&nbsp;</a>
                    </Trans>
                </span>
                <a className={s.contactsDiscordLink} rel="nofollow noopener noreferrer" target="_blank" href="https://discord.gg/n72AvaTAfN">
                    <DiscordSvg className={s.contactsDiscordImage} />
                </a>
            </section>
            
            <MapsModal show={isMapsModalOpen} onHide={() => setMapsModalOpen(false)} />
            <PatchsModal show={isPatchsModalOpen} onHide={() => setPatchsModalOpen(false)} />
        </main>
    );
}

export default Home;
