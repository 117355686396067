import React from 'react';

import {ReactComponent as SpinnerSvg} from '../../assets/spinner.svg';
import s from './index.module.css';  


function Spinner(props: {className?: string}) {
    const className = s.spinner + (props?.className ? ' ' + props.className : '');

    return (
        <SpinnerSvg className={className} />
    );
}

export default Spinner;