import React, {PropsWithChildren} from 'react';

import s from './index.module.css';


export interface ButtonProps {
    href?: string;
    type?: 'submit' | 'button';
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function Button(props: PropsWithChildren<ButtonProps>) {
    return props.href ? (
        <a className={s.button} href={props.href}>
            {props.children}
        </a>
    ) : (
        <button
            className={s.button}
            type={props.type}
            onClick={(e) => props.onClick && props.onClick(e)}
        >
            {props.children}
        </button>
    );
}
