import camelcaseKeys from 'camelcase-keys';

import Tools from '../tools/tools.service';
import {GameMap} from './game-map.model';
import {StatisticFilter} from './statistic-filter.model';
import {StatisticItem} from './statistic-item.model';
import {GetModFamilyResponse} from './get-mod-family-response.model';
import {ModFamily} from './mod-family.model';
import {ModLite} from './mod-lite.model';


export default class ApiService {
    private static apiService: ApiService;

    private constructor() {}

    static get instance(): ApiService {
        if (!this.apiService)
            this.apiService = new ApiService();

        return this.apiService;
    }

    async getOnlinePlayers(): Promise<number> {
        if (Tools.instance.isPreRendering) return 0;

        const response = await fetch('/api/onlinestate');
        const json = await response.json();

        return json.onlinePlayers.length;
    }

    async getMaps(): Promise<GameMap[]> {
        if (Tools.instance.isPreRendering) return [];

        const response = await fetch('/api/mods/listLite?modType=Map');
        const json = await response.json();

        return camelcaseKeys<ModLite[]>(json)
            .map(item => ({
                id: item.id,
                name: item.modName,
                image: `/Storage/ModIcons/${item.id}.jpg`
            }))
            .sort((a, b) =>
                a.name > b.name ? 1 : -1
            );
    }

    async getStats(filter: StatisticFilter): Promise<StatisticItem[]> {
        if (Tools.instance.isPreRendering) return [];

        const query = new URLSearchParams();

        Object.entries(filter).forEach(item =>
            item[1] !== undefined &&
            query.append(item[0], String(item[1]))
        );

        const response = await fetch('/api/stats/list?' + query.toString());
        const json = await response.json();

        return camelcaseKeys<StatisticItem[]>(json);
    }

    async getModFamilies(): Promise<ModFamily[]> {
        if (Tools.instance.isPreRendering) return [];

        const response = await fetch('/api/mods/modfamilies');
        const json = await response.json();

        const result: GetModFamilyResponse = camelcaseKeys<GetModFamilyResponse>(json);
        return result.modFamilies;
    }
}