export default class Tools {
    private static toolsService: Tools;

    private constructor() {}

    static get instance(): Tools {
        if (!this.toolsService)
            this.toolsService = new Tools();

        return this.toolsService;
    }

    get isPreRendering(): boolean {
        return navigator.userAgent === 'ReactSnap';
    }
}