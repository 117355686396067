import i18n, {Resource} from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationRU from './locales/ru.json';
import translationEN from './locales/en.json';
import translationES from './locales/es.json';


export const LANGUAGES = [
    {
        id: 0,
        label: 'English',
        code: 'en',
        resources: translationEN
    }, {
        id: 1,
        label: 'Русский',
        code: 'ru',
        resources: translationRU
    }, {
        id: 2,
        label: 'Español',
        code: 'es',
        resources: translationES
    }
];

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: LANGUAGES
            .reduce<Resource>((acc, item) => {
                acc[item.code] = {translation: item.resources};
                return acc;
            }, {}),
        fallbackLng: LANGUAGES[0].code,
        whitelist: LANGUAGES.map(item => item.code),
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
            lookupLocalStorage: 'lang',
            checkWhitelist: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'p']
        }
    });

export default i18n;