import React, {PropsWithChildren, useEffect} from 'react';

import {ReactComponent as CloseSvg} from '../../assets/close.svg';
import s from './index.module.css';


function Modal(props: PropsWithChildren<{title?: string, show: boolean, onHide: () => void}>) {
    if (props.show) {
        document.body.style.overflowY = 'hidden';
    }

    const hide = () => {
        props.onHide();
        document.body.style.overflowY = 'auto';
    };

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.keyCode === 27)
            hide();
    };

    const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (e.target === e.currentTarget) 
            hide();
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    });

    return props.show ? (
        <section className={s.container} onClick={e => onClick(e)}>
            <section className={s.modal}>
                {props.title && <h2>{props.title}</h2>}
                {props.children}
            </section>
            <CloseSvg className={s.close}/>
        </section>
    ) : null;
}

export default Modal;
