import React from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '../../../shared/Modal';

import s from './index.module.css';


function PatchsModal(props: {show: boolean, onHide: () => void}) {
    const {t} = useTranslation();
    
    return (
        <Modal title={t('resources.patchs')} show={props.show} onHide={() => props.onHide()}>
            <section className={s.container}>
                <article>
                    <h3>{t('home.patchs.0.title')}</h3>
                    <span>{t('home.patchs.0.text')}</span>
                </article>

                <article>
                    <h3>{t('home.patchs.1.title')}</h3>
                    <span>{t('home.patchs.1.text')}</span>
                </article>

                <article>
                    <h3>{t('home.patchs.2.title')}</h3>
                    <span>{t('home.patchs.2.text')}</span>
                </article>
            </section>
        </Modal>
    );
}

export default PatchsModal;
