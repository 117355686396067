import React from 'react';
import Helmet from 'react-helmet';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import loadable from '@loadable/component';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';


const Statistic = loadable(() => import('./pages/Statistic'));

function App() {
    const {t, i18n} = useTranslation();

    return (
        <>
            <Helmet htmlAttributes={{lang: i18n.language}}>
                <title>{t('helmet.title')}</title>
                <meta name="description" content={t('helmet.description')} />

                <meta property="og:title" content={t('helmet.title')} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://dowonline.ru/${i18n.language}/`} />
                <meta property="og:image" content={`https://dowonline.ru/socilal-networks-${i18n.language}.png`} />
                <meta property="og:image:width" content="968" />
                <meta property="og:image:height" content="504" />
                <meta property="og:site_name" content="DowOnline" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={t('helmet.title')} />
                <meta name="twitter:description" content={t('helmet.description')} />
                <meta name="twitter:image" content={`https://dowonline.ru/socilal-networks-${i18n.language}.png`} />
            </Helmet>

            <BrowserRouter basename={i18n.language}>
                <Header />

                <Switch>
                    
                    <Route exact path="/">
                        <Home />
                    </Route>

                    <Route exact path="/stats/">
                        <Statistic />
                    </Route>

                </Switch>

                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
