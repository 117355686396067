//https://github.com/potato4d/react-muted-video-player
import React, {useState, useRef, useLayoutEffect} from 'react';


function toBool(val: boolean | null | undefined, defaultValue: boolean) {
    if (val === undefined) {
        return defaultValue;
    }
    return !!val;
}

type MutedPlayerProps = JSX.IntrinsicElements['video'];

export default function MutedPlayer(props: MutedPlayerProps) {
    const {autoPlay, muted, playsInline, ...p} = props;
    const [dispatched, setDispatched] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useLayoutEffect(() => {
        if (dispatched)
            return;

        if (!videoRef || !videoRef.current)
            return;

        setDispatched(true);

        videoRef.current.setAttribute('muted', '1');
        videoRef.current.setAttribute('playsinline', '1');
        videoRef.current.setAttribute('autoplay', '1');
    }, [dispatched, setDispatched]);

    return (
        <video
            {...p}
            ref={videoRef}
            autoPlay={toBool(autoPlay, true)}
            muted={true}
            playsInline={toBool(playsInline, true)}
        />
    );
}